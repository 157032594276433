import React from 'react'
import { BasicSearch } from '../components/search/basicSearch'
import { LayoutWrapper } from '../components/layout/layoutWrapper'
import { AppLocale, GenerateLocales } from '@hrk/types'
import { Seo } from '../components/seo/Seo'
import { useNavigationItems } from '../hooks/useNavigationItems'
import { DownloadContextProvider } from '../context/DownloadContext'
import { SiteMetadata, useSiteMetadata } from '../hooks/useSiteConfig'

const Suche: React.FC = () => {
  const currentSlug = '/suche'
  const currentLocale: AppLocale = GenerateLocales.de
  const seoTitle = 'Suche'
  const seoDescription = 'Die Suche von hoch und weit'

  const { collectBreadCrumbs } = useNavigationItems()
  const currentPagePath = collectBreadCrumbs({ slug: currentSlug, title: seoTitle, type: 'EditorialPages' })

  return (
    <>
      <Seo title={seoTitle} slug={currentSlug} locale={currentLocale} description={seoDescription}></Seo>
      <DownloadContextProvider>
        <LayoutWrapper
          currentTitle={seoTitle}
          currentLocale={currentLocale}
          currentSlug={currentSlug}
          currentPath={currentPagePath}
          otherLocales={[{ locale: GenerateLocales.en, slug: '/en/search/' }]}
          styleProps={{ overflow: 'visible' }}
        >
          <BasicSearch />
        </LayoutWrapper>
      </DownloadContextProvider>
    </>
  )
}

export const Head = ({ location, params, data, pageContext }) => {
  const site: SiteMetadata = useSiteMetadata(pageContext.locale as AppLocale)
  return <link rel="canonical" href={`${site.metadata.siteUrl}/suche/`} />
}

export default Suche
